/* src/components/LoginPage.css */
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(270deg, #111, #666); /* 반대 방향으로 회색에서 검은색으로의 그라데이션 */
  padding: 20px;
  color: white;
  font-family: 'Roboto', sans-serif;
}

.login-container h1 {
  font-size: 4rem; /* 텍스트 크기를 크게 설정 */
  margin-bottom: 60px;
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  letter-spacing: 0.2rem;
  text-shadow: 0px 4px 6px rgba(255, 255, 255, 0.3); /* 수정된 텍스트 그림자 */
  background: linear-gradient(30deg, rgba(255,255,255,0.6) 10%, rgba(255,255,255,1) 40%, rgba(255,255,255,0.6) 50%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,0.4) 90%); /* 수정된 그라디언트 */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}


.google-login-button {
  background-color: #999999; /* 흰색 배경 */
  background: linear-gradient(130deg, rgba(255,255,255,1) 10%, rgba(255,255,255,1) 40%, rgba(255,255,255,0.9) 50%, rgba(255,255,255,0.7) 75%, rgba(255,255,255,0.5) 90%);
  border: none;
  padding: 10px 10px;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3); /* 버튼 그림자 강화 */
  transition: transform 0.3s, box-shadow 0.3s;
  font-size: 1rem; /* 텍스트 크기 크게 설정 */
  color: #333; /* 텍스트 색상 설정 */
  font-weight: bold; /* 텍스트를 두껍게 */
}

.google-login-button:hover {
  transform: translateY(-5px); /* 호버 시 더 높이 이동 */
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.5); /* 호버 시 그림자 강화 */
}

/* 구글 로그인 버튼 내 텍스트 스타일링 */
.google-login-button span {
  color: #333; /* 텍스트 색상 */
  font-weight: bold; /* 텍스트 두껍게 설정 */
  margin-left: 10px;
}
/* Google 로고의 크기 조정 */
.google-logo {
  width: 60px; /* 로고의 폭 조정 */
  height: 20px; /* 로고의 높이 조정 */
  margin-right: 10px; /* 로고와 텍스트 사이의 마진 */
}

/* 모바일 스타일 */
@media (max-width: 768px) {
  .google-logo {
    width: 100px; /* 로고의 폭 조정 */
    height: 25px; /* 로고의 높이 조정 */
    margin-right: 0px; /* 로고와 텍스트 사이의 마진 */
  }

  .login-container h1 {
    font-size: 2.5rem; /* 텍스트 크기를 크게 설정 */
    margin-bottom: 60px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    letter-spacing: 0.2rem;
    text-shadow: 10px 10px 10px rgba(20, 20, 20, 0.4); /* 강한 텍스트 그림자 */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }

  .google-login-button {
    font-size: 0.9rem; /* 텍스트 크기 크게 설정 */
  }

}
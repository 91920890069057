/* src/StockPage.css */
.stock-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: white;
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  min-height: 100vh;
}

.stock-container h1 {
  margin-bottom: 20px;
  font-size: 2rem;
}

.search-bar {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  max-width: 600px;
}

.search-input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #444;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: white;
  background-color: #1e1e1e;
}

.search-input::placeholder {
  color: #888;
}

.search-button {
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 0 5px 5px 0;
  background-color: #00aaff;
  color: white;
  cursor: pointer;
  outline: none;
}

.search-button:hover {
  background-color: #0088cc;
}

.model-list {
  list-style: none;
  padding: 0;
  width: 100%;
  max-width: 600px;
}

.model-item {
  border: 1px solid #ddd;
  margin: 10px 0;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  background-color: #1e1e1e;
}

.model-item:nth-child(even) {
  background-color: #2e2e2e;
}

.model-item span {
  display: inline-block;
  min-width: 80px;
  text-align: left;
}

.model-item .ticker {
  font-weight: bold;
}

.model-item .name {
  flex: 1;
  padding-left: 10px;
}

.model-item .monthly-return, .model-item .subscription-price {
  text-align: right;
}

.logout-button {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  outline: none;
  background-color: #f44;
  color: white;
  border-radius: 5px;
  margin-top: 20px;
}

.logout-button:hover {
  background-color: #d33;
}

/* 모바일 스타일 */
@media (max-width: 768px) {
  .stock-container {
    padding: 10px;
  }

  .search-bar {
    flex-direction: column;
  }

  .search-input {
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .search-button {
    border-radius: 5px;
  }

  .model-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .model-item span {
    min-width: auto;
    text-align: left;
  }

  .model-item .monthly-return, .model-item .subscription-price {
    text-align: left;
  }
}

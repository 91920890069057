/* 최상단 바 스타일 */
.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px; /* 좌우 패딩 추가 */
  z-index: 1000;
  box-sizing: border-box; /* 패딩 포함하여 width 계산 */
}

.top-bar-logo {
  font-size: 1.5rem !important;  /* 텍스트 크기를 크게 설정 */
  color: #ffffff;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  letter-spacing: 0.1rem;
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.settings-icon {
  font-size: 1.5rem;
  color: white;
  margin-right: 10px; /* 오른쪽 끝에서 떨어지도록 마진 추가 */
}

/* 하단 네비게이션 바 스타일 */
.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #333;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;
}

.bottom-navbar-menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.nav-icon {
  font-size: 1rem;
  margin-bottom: 5px;
}

.nav-link span {
  font-size: 0.8rem;
}

.nav-link:hover {
  color: #00aaff; /* 호버 시 색상 변경 */
}

/* 네비게이션 바 하단 여백 추가 (화면 콘텐츠가 네비게이션 바 아래로 숨지 않도록) */
body {
  padding-bottom: 60px; /* 하단 네비게이션 바 높이만큼 추가 */
}

/* 콘텐츠와 네비게이션 바 간의 여백 조절 */
.content {
  padding-top: 60px; /* 최상단 바 높이만큼 추가 */
}

/* 모바일에서의 스타일링 */
@media (max-width: 768px) {
  .top-bar {
    padding: 0 10px; /* 모바일 화면에서 패딩 줄이기 */
  }

  .settings-icon {
    font-size: 1.3rem; /* 모바일에서 아이콘 크기 줄이기 */
    margin-right: 5px; /* 모바일에서 마진 줄이기 */
  }
}

/* 하단 네비게이션 바 스타일 */
.bottom-navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #333;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 1000;
}

.bottom-navbar-menu {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.nav-link {
  color: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

.nav-icon {
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.nav-link span {
  font-size: 0.8rem;
}

.nav-link:hover {
  color: #00aaff; /* 호버 시 색상 변경 */
}

/* 네비게이션 바 하단 여백 추가 (화면 콘텐츠가 네비게이션 바 아래로 숨지 않도록) */
body {
  padding-bottom: 60px; /* 하단 네비게이션 바 높이만큼 추가 */
}

/* 콘텐츠와 네비게이션 바 간의 여백 조절 */
.content {
  padding-top: 60px; /* 최상단 바 높이만큼 추가 */
}
